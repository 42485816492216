import { backgroundColor, mediaMax, primaryColor } from '@styles/utils';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const PageTopBar = styled.div`
  display: flex;
  align-items: center;
  min-height: 71px;
  padding: 0 30px;
  background: #fff;
  border-bottom: 1px solid #ddd;

  .ui.breadcrumb {
    a.section {
      color: ${primaryColor};
    }

    .section {
      font-weight: 600;
    }
  }

  @media screen and (${mediaMax.large}) {
    padding-left: 65px;
  }
`;

export const PageTopBarLeft = styled.div`
  flex: 1 1 auto;

  .breadcrumb .section {
    display: inline-flex;
    align-items: center;
  }
`;

export const PageToBarRight = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

export const PageChildren = styled.div<{ $noPadding?: boolean }>`
  flex: 1 1 auto;
  padding-bottom: ${props => (props.$noPadding ? '0' : '30px')};
`;

export const PageBottomBar = styled.div`
  min-height: 60px;
  background: ${backgroundColor()};
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.04);

  > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (${mediaMax.tablet}) {
      padding: 0 5px;
    }

    .button {
      margin-right: 7px !important;
      outline: none;
    }
  }
`;

export const PageBottomBarLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0;

  @media screen and (${mediaMax.mobile}) {
    > button,
    a {
      width: 100%;
    }
  }
`;

export const PageBottomBarRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 0;

  @media screen and (${mediaMax.mobile}) {
    > button,
    a {
      width: 100%;
    }
  }
`;
