import React, { FC, useEffect, useState } from 'react';
import { Typography } from '@styles/shared';

const displayTimer = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const Timer: FC = () => {
  const [seconds, setSeconds] = useState(0);
  const [isActive] = useState(true);

  useEffect(() => {
    let interval: any = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <Typography as="span" $level="name">
      {displayTimer(seconds)}
    </Typography>
  );
};

export default Timer;
