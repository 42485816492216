import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';

export const FiltersContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  background-color: ${backgroundColor('100')};
  margin-bottom: 15px;
  width: 100%;
`;
