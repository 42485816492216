import React, { createContext, Dispatch, FC, SetStateAction, Suspense, useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header/Header';
import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { constVoid } from 'fp-ts/function';
import { Residence } from '@shared/model/residence';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import Footer from './footer/Footer';

export const LAYOUT_CONTENT_ID = 'main';

export interface LayoutProps {
  residence?: Residence;
  hideHeader?: boolean;
  hideFooter?: boolean;
}

type LayoutContextValue = [props: LayoutProps, setProps: Dispatch<SetStateAction<LayoutProps>>];

const LayoutContext = createContext<LayoutContextValue>([{}, constVoid]);

const Layout: FC = () => {
  const [props, setProps] = useState<LayoutProps>(() => ({ hideFooter: false, hideHeader: false }));

  const ctx = useMemo<LayoutContextValue>(() => [props, setProps], [props]);

  const newTheme = (theme: DefaultTheme): DefaultTheme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: props.residence?.primaryColor ?? theme.colors.primary,
      secondary: props.residence?.secondaryColor ?? theme.colors.secondary,
      tertiary: props.residence?.tertiaryColor ?? theme.colors.tertiary,
      headerBackground: props.residence?.backgroundColor ?? theme.colors.headerBackground,
    },
  });

  return (
    <LayoutContext.Provider value={ctx}>
      <ThemeProvider theme={newTheme}>
        <Header />
        <Styled.LayoutContainer $hasHeader={!!props.residence}>
          <Styled.LayoutContent id={LAYOUT_CONTENT_ID} $hasFooter={!props.hideFooter}>
            <Suspense fallback={<DebouncedLineLoader />}>
              <Outlet />
            </Suspense>
          </Styled.LayoutContent>
        </Styled.LayoutContainer>
        <Footer hasHeader={!!props.residence} />
      </ThemeProvider>
    </LayoutContext.Provider>
  );
};

export default Layout;

export function useLayoutContext() {
  return useContext(LayoutContext);
}
