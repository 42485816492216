import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { constVoid } from 'fp-ts/function';
import audio from '@assets/sound/sounds.mp3';
import useAudio from './hooks';
import { safeVibrate } from '@shared/utils/vibrate';
import { delay } from '@shared/utils/delay';

type AudioName = 'init' | 'door-open' | 'door-closed' | 'msg-sent' | 'ring';

export interface AudioContextValue {
  playAudio: (audio: AudioName) => void;
  stop: () => void;
}

const AudioContext = createContext<AudioContextValue>({
  playAudio: constVoid,
  stop: constVoid,
});

export const AudioContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { play, pause, stop, setCurrentTime } = useAudio(audio, {});

  const playAudio = (audio: AudioName) => {
    switch (audio) {
      case 'init':
        setCurrentTime(0);
        play();
        pause();
        break;
      case 'door-open':
        setCurrentTime(2);
        play();
        safeVibrate(300);
        delay(2000).then(() => pause());
        break;
      case 'door-closed':
        setCurrentTime(5);
        play();
        safeVibrate([100, 100, 100, 100, 100]);
        delay(2000).then(() => pause());
        break;
      case 'msg-sent':
        setCurrentTime(8);
        play();
        safeVibrate(300);
        delay(1800).then(() => pause());
        break;
      case 'ring':
        setCurrentTime(10);
        play();
        break;
    }
  };

  const ctx: AudioContextValue = { playAudio, stop };

  return <AudioContext.Provider value={ctx}>{children}</AudioContext.Provider>;
};

export const useAudioContext = (): AudioContextValue => useContext(AudioContext);
