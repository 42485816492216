import React, { FC } from 'react';

import ReactSticky from 'react-sticky-el';
import { LAYOUT_CONTENT_ID } from '../../../layout/Layout';

interface StickyProps extends ReactSticky.Props {
  offset?: number;
  prevSticky?: string;
}

const Sticky: FC<StickyProps> = ({
  boundaryElement = LAYOUT_CONTENT_ID,
  offset: receivedOffset = 0,
  prevSticky,
  stickyStyle,
  children,
  className,
  ...rest
}) => {
  const totalOffset = receivedOffset;

  return (
    <ReactSticky
      {...rest}
      boundaryElement={`#${boundaryElement}`}
      stickyStyle={{ ...stickyStyle, marginTop: totalOffset, zIndex: 800 }}
      topOffset={-totalOffset}
      bottomOffset={totalOffset}
      className={className}>
      {children}
    </ReactSticky>
  );
};

export default Sticky;
