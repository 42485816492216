import React, { FC, PropsWithChildren } from 'react';
import Icon, { IconType } from '@shared/components/icons/Icon';
import { Link } from 'react-router-dom';
import * as Styled from './Button.styles';

export type ButtonType = 'primary' | 'secondary';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: any;
  to?: string;
  replace?: boolean;
  icon?: IconType;
  btnType?: ButtonType;
  loading?: boolean;
}

export const SharedButton: FC<ButtonProps> = ({
  as,
  to,
  replace,
  icon,
  btnType = 'primary',
  disabled,
  loading,
  className,
  children,
  ...buttonProps
}) => {
  const buttonDisabled = loading || disabled;

  return (
    <Styled.SharedButtonContainer
      as={as}
      to={to}
      replace={replace}
      $btnType={btnType}
      className={className}
      {...buttonProps}
      disabled={buttonDisabled}
      $hasIcon={!!icon}
    >
      {icon && <Icon type={icon} size={20} />}
      <span>{children}</span>
    </Styled.SharedButtonContainer>
  );
};

export interface SharedButtonLinkProps {
  to: string;
  className?: string;
}

export const SharedButtonLink: FC<PropsWithChildren<SharedButtonLinkProps>> = ({ to, className, children }) => {
  return (
    <Styled.SharedButtonLinkContainer as={Link} to={to} className={className}>
      {children}
    </Styled.SharedButtonLinkContainer>
  );
};
