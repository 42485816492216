import { backgroundColor, primaryColor, rgba } from '@styles/utils';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  flex: 0 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${backgroundColor('100')};
  border-top: solid 1px #e5e5e5;
  padding: 12px 22px;
  height: 77px;
  z-index: 10000;

  &:before {
    content: '';
    position: absolute;
    top: -31px;
    background: linear-gradient(to top, ${rgba(backgroundColor('100'), 1)}, ${rgba(backgroundColor('100'), 0)});
    width: 100%;
    height: 30px;
    z-index: 10;
  }
`;

export const FooterButtons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${backgroundColor('300')};
  border-radius: 41px;
  overflow: hidden;
  height: 100%;
  width: 100%;

  svg {
    fill: ${rgba(backgroundColor('800'), 0.7)};
    transition: 0.3s ease-in-out fill;
  }

  .active {
    span {
      color: ${backgroundColor('100')};
      transition: 0.3s ease-in-out color;
    }

    svg {
      fill: ${backgroundColor('100')};
    }
  }
`;

const FooterButton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 41px;
  height: 100%;
  width: 50%;
  z-index: 1;
  outline: none;
  text-decoration: none !important;
  cursor: pointer;

  > span {
    color: ${rgba(backgroundColor('800'), 0.7)};
  }

  > svg {
    margin-right: 9px;
  }
`;

export const FooterListButton = styled(FooterButton)`
  > svg {
    width: 30px;
  }
`;

export const FooterCodeButton = styled(FooterButton)`
  > svg {
    width: 27px;
  }
`;

export const FooterButtonAnimation = styled.div<{ $left: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${props => (props.$left ? '0' : '50%')};
  border-radius: 41px;
  background-color: ${primaryColor} !important;
  width: 50%;
  transition: 0.3s ease-in-out left;
`;
