import { backgroundColor } from '@styles/utils';
import styled from 'styled-components';

export const CodeErrorContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 20px;
  height: 100%;
`;

export const CodeErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    margin: 0 0 15px 0;
  }
`;

export const CodeErrorButton = styled.button`
  font-size: 18px;
  font-weight: 500;
  color: ${backgroundColor('800')};
  background-color: ${backgroundColor('100')};
  border-radius: 41px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  outline: none;
  padding: 16px 21px;
  height: 55px;
  margin-top: 30px;

  &:hover {
    color: inherit;
  }
`;
