import { Routes } from '@core/router/Routes';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import Call from './Call';
import MessageRoutes from './message/routes';

const CallRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Call />} />
      <Route path="message/*" element={<MessageRoutes />} />
    </Routes>
  );
};

export default CallRoutes;
