import { backgroundColor } from '@styles/utils';
import styled from 'styled-components';

export const CallButtonContainer = styled.button`
  background-color: ${backgroundColor('100')};
  border: 1px solid ${backgroundColor('500')};
  border-radius: 70px;
  padding: 0;
  margin-bottom: 20px;
  height: 85px;
  width: 190px;
  outline: none;
  cursor: pointer;
  z-index: 1;

  > div {
    position: relative;
    top: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  p {
    margin: 0;
  }

  svg {
    flex: 0 0 auto;
  }
`;
