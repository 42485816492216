import React, { FC, PropsWithChildren, Suspense } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { HttpError } from '@core/http';
import ErrorPage from '@shared/components/error-page/ErrorPage';

export const Routes: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <Suspense fallback={<DebouncedLineLoader />}>
    <ReactRoutes>
      {children}
      <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
    </ReactRoutes>
  </Suspense>
);
