import { Typography } from '@styles/shared';
import { backgroundColor } from '@styles/utils';
import { rowGap } from '@styles/utils/gap';
import styled, { keyframes } from 'styled-components';

export const CallingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${rowGap('15px')};
  padding-top: 25px;
  z-index: 1;
`;

export const CallingNoResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const CallingText = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

export const dotFlashing = keyframes`
  0% {
    background-color: #ffff;
  }
  50%,
  100% {
    background-color: rgb(255,255,255, 0.3);
  }
`;

export const CallingAnimation = styled.span`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${backgroundColor('100')};
  color: ${backgroundColor('100')};
  animation: ${dotFlashing} 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-top: 4px;
  margin-left: 25px;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${backgroundColor('100')};
    color: ${backgroundColor('100')};
    animation: ${dotFlashing} 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${backgroundColor('100')};
    color: ${backgroundColor('100')};
    animation: ${dotFlashing} 1s infinite alternate;
    animation-delay: 1s;
  }
`;
