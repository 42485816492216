import styled from 'styled-components';

export const LayoutContainer = styled.div<{ $hasHeader: boolean }>`
  flex: 1 1 auto;
  position: relative;
  display: flex;
`;

export const LayoutContent = styled.div<{ $hasFooter?: boolean }>`
  position: relative;
  flex: 1 1 auto;
  padding-bottom: ${props => (props.$hasFooter ? '77px' : '0')};
`;
