import React, { FC, useEffect } from 'react';
import { useLayoutContext } from '@layout/Layout';
import seeYouSoonAnimation from '@assets/animations/see-you-soon.json';
import * as Styled from './EndCall.styles';
import { Typography } from '@styles/shared';
import { Resident } from '@shared/model/resident';
import { PageProps } from '@layout/page/Page';

interface EndCallProps {
  resident: Resident;
  updatePageProps: (props: PageProps) => void;
}

const EndCall: FC<EndCallProps> = ({ resident, updatePageProps }) => {
  const [props, setProps] = useLayoutContext();

  useEffect(() => {
    updatePageProps({ title: 'ouverte' });
  }, [updatePageProps]);

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: true }));
  }, [setProps]);

  return (
    <Styled.EndCallContainer aria-live="assertive">
      <Styled.EndCallContent>
        <Styled.EndCallAnimation
          animationData={seeYouSoonAnimation}
          style={{ width: 300 }}
          settings={{ loop: false }}
        />
        <Styled.EndCallTextContainer>
          <Typography $level="standard">On vous attend</Typography>
          <Styled.EndCallInfos $level="large" as="div">
            <p>{props.residence?.building}</p>
            <p>Numéro {resident?.flatNumber}</p>
          </Styled.EndCallInfos>

          <Styled.EndCallResidentInfos>
            <Styled.EndCallResidentAvatar resident={resident} size={36} />

            <Typography $level="medium" $weight="bold">
              Aller au 5ième étage
            </Typography>
          </Styled.EndCallResidentInfos>
        </Styled.EndCallTextContainer>
      </Styled.EndCallContent>
      <Typography $level="standard">A bientôt !</Typography>{' '}
      <Typography $level="small">Vous pouvez fermer l'onglet</Typography>
    </Styled.EndCallContainer>
  );
};

export default EndCall;
