import { Routes } from '@core/router/Routes';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import CheckMessage from './check/CheckMessage';
import MessageFinish from './finish/MessageFinish';
import Message from './Message';

const MessageRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Message />} />
      <Route path="check" element={<CheckMessage />} />
      <Route path="sent" element={<MessageFinish sent />} />
      <Route path="end" element={<MessageFinish sent={false} />} />
    </Routes>
  );
};

export default MessageRoutes;
