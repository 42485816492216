import React, { FC, useEffect, useState } from 'react';
import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import { Typography } from '@styles/shared';
import * as Styled from './MessageFinish.styles';
import sendAnimation from '@assets/animations/send.json';
import seeYouSoonAnimation from '@assets/animations/see-you-soon.json';
import Page, { PageProps } from '@layout/page/Page';
import { useLayoutContext } from '@layout/Layout';
import { useAudioContext } from '@shared/modules/audio/context';

interface MessageFinishProps {
  sent: boolean;
}

const MessageFinish: FC<MessageFinishProps> = ({ sent = false }) => {
  const { playAudio } = useAudioContext();

  const [played, setPlayed] = useState(false);

  useEffect(() => {
    if (sent && !played) {
      playAudio('msg-sent');
      setPlayed(true);
    }
  }, [played, sent, playAudio]);

  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: true }));
  }, [setProps]);

  const pageProps: PageProps = {
    title: 'Message envoyé',
  };

  return (
    <Page {...pageProps}>
      <Styled.MessageFinishContainer $sent={sent}>
        <AnimatedIcon
          animationData={sent ? sendAnimation : seeYouSoonAnimation}
          style={{ width: sent ? 150 : 250 }}
          settings={{ loop: true }}
        />
        <Styled.MessageTextContainer $sent={sent} aria-live="assertive">
          <Typography $level="h1" as="h1">
            A bientôt !
          </Typography>
          {sent && <Typography $level="standard">Votre message a été envoyé</Typography>}
          <Typography $level="small">Vous pouvez fermer l'onglet</Typography>
        </Styled.MessageTextContainer>
      </Styled.MessageFinishContainer>
    </Page>
  );
};

export default MessageFinish;
