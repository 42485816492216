import { backgroundColor, rgba } from '@styles/utils';
import styled, { css } from 'styled-components';
import { CallStatus } from './model';

export const CallContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  z-index: 1;
`;

const getOpacityByStatus = (status: CallStatus): number => {
  switch (status) {
    case 'waiting':
    case 'end-call':
      return 0;
    case 'no-response':
      return 0.8;
    default:
      return 0.4;
  }
};

export const CallVideoCamera = styled.div<{ $status: CallStatus }>`
  position: absolute;

  ${props =>
    props.$status === 'in-call' || props.$status === 'call-ending'
      ? css`
          top: 10px;
          right: 12px;
          border-radius: 15px;
          overflow: hidden;
          max-height: 200px;
          width: 105px;
          z-index: 1;

          > img,
          > video {
            height: 100%;
            width: 100%;
            max-width: none;
            transform: scaleX(-1);
          }
        `
      : props.$status === 'end-call'
      ? css`
          display: none;
        `
      : css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;
          z-index: 0;

          > img,
          > video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) scaleX(-1);
            object-fit: cover;
            height: calc(100%);
            width: calc(100%);
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${rgba(backgroundColor('900'), getOpacityByStatus(props.$status))};
            width: 100%;
            height: 100%;
            z-index: 1;
          }
        `}
`;
