import styled from 'styled-components';

interface ScreenReaderOnlyProps {
  $strict?: boolean;
}

export const ScreenReaderOnly = styled.span.attrs<ScreenReaderOnlyProps>(props => ({
  'aria-live': props.$strict ? 'assertive' : 'polite',
}))<ScreenReaderOnlyProps>`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;
