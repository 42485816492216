import React, { FC, PropsWithChildren, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import RootRoutes from 'routes';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';

import ToastsProvider from '@shared/components/toasts/ToastsProvider';
import { GlobalStyles } from '@styles/global';
import theme from '@styles/theme';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';

const ScrollRestoration: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const App: FC = () => {
  // cheat 100vh on mobile
  useEffect(() => {
    const updateViewHeight = () => {
      const root = document.getElementById('root');

      if (root) {
        root.style.minHeight = `${window.innerHeight}px`;
      }
    };

    window.addEventListener('resize', updateViewHeight);

    return () => {
      window.removeEventListener('resize', updateViewHeight);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastsProvider />

      <HelmetProvider>
        <BrowserRouter>
          <ScrollRestoration>
            <Routes>
              <Route path="/*" element={<RootRoutes />} />
              <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
            </Routes>
          </ScrollRestoration>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
