import React, { FC, ReactNode } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import playIcon from '@assets/icons/message/play.svg';
import pauseIcon from '@assets/icons/message/pause.svg';
import playFullIcon from '@assets/icons/message/play-full.svg';
import stopIcon from '@assets/icons/message/stop.svg';
import sendIcon from '@assets/icons/message/send.svg';

const getColor = (theme: DefaultTheme, colorType: ColorType): string => {
  return theme.colors[colorType];
};

const getIcon = (type: IconType, color: string, size?: number, className?: string): ReactNode => {
  switch (type) {
    case 'phone-hangup':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size ?? 44.819}
          height={size ?? 44.819}
          viewBox="0 0 44.819 44.819"
          className={className}
        >
          <path
            d="M125.5 57.391s.119-1.187.831-1.306c.831-.119 7-.594 7-.594s1.306.237 1.306 1.424a14.517 14.517 0 0 0 1.187 4.748c.831 1.781.712 2.967 0 3.561a33.981 33.981 0 0 0-3.561 3.442 58.621 58.621 0 0 0 11.751 11.87s2.967-2.849 3.561-3.561c.594-.831 1.781-.831 3.561-.119a13.28 13.28 0 0 0 4.629 1.187 1.755 1.755 0 0 1 1.424 1.424s-.356 6.172-.594 6.884c-.119.712-1.306.831-1.306.831C140.812 86 126.687 71.872 125.5 57.391z"
            transform="rotate(135 92.01 22.24)"
            fill={color}
            fillRule="evenodd"
          />
          <path data-name="Rectangle 10460" transform="translate(0 .819)" fill="none" d="M0 0h44v44H0z" />
        </svg>
      );
    case 'send':
      return <img src={sendIcon} alt={type} width={size} className={className} />;
    case 'play':
      return <img src={playIcon} alt={type} width={size} className={className} />;
    case 'play-full':
      return <img src={playFullIcon} alt={type} width={size} className={className} />;
    case 'stop':
      return <img src={stopIcon} alt={type} width={size} className={className} />;
    case 'pause':
      return <img src={pauseIcon} alt={type} width={size} className={className} />;
    default:
      return null;
  }
};

export type IconType = 'phone-hangup' | 'send' | 'play' | 'play-full' | 'stop' | 'pause';
export type ColorType = 'primary' | 'secondary' | 'tertiary';

interface ComponentProps {
  type: IconType;
  colorType?: ColorType;
  size?: number;
  className?: string;
}

const Icon: FC<ComponentProps> = ({ type, colorType = 'primary', size, className }) => {
  const theme = useTheme();

  const color = getColor(theme, colorType);

  return <> {getIcon(type, color, size, className)}</>;
};

export default Icon;
