import React, { FC } from 'react';
import Icon from '@shared/components/icons/Icon';
import { Typography } from '@styles/shared';
import * as Styled from './CallButton.styles';
import { Link } from 'react-router-dom';
import { ResidenceSlug } from '@shared/model/residence';
import { useIdParam } from '@core/router';
import { renderConditional } from '@shared/utils/render';

interface CallButtonProps {
  to?: string;
  onClick?: () => void;
}

const CallButton: FC<CallButtonProps> = ({ to, onClick }) => {
  const slug = useIdParam<ResidenceSlug>('slug');

  return renderConditional(
    !!onClick,
    () => (
      <Styled.CallButtonContainer onClick={onClick}>
        <div>
          <Icon type="phone-hangup" colorType="primary" />
          <Typography $level="medium">Raccrocher</Typography>
        </div>
      </Styled.CallButtonContainer>
    ),
    () => (
      <Styled.CallButtonContainer as={Link} to={to ?? `/${slug}`}>
        <div>
          <Icon type="phone-hangup" colorType="primary" />
          <Typography $level="medium">Raccrocher</Typography>
        </div>
      </Styled.CallButtonContainer>
    ),
  );
};

export default CallButton;
