import { backgroundColor, rgba, successColor } from '@styles/utils';
import { rowGap } from '@styles/utils/gap';
import styled from 'styled-components';

export const InCallContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 0 20px;
  background-color: ${backgroundColor('100')};
  height: 100%;
  width: 100%;
`;

export const InCallContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${rowGap('15px')};
  width: 100%;
  z-index: 1;
`;

export const InCallTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  z-index: 1;

  > div {
    > div {
      margin-bottom: 5px;

      > span:last-of-type {
        margin-left: 40px;
      }
    }
  }
`;

export const InCallBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;
`;

export const MockInCallVideoNotPlaying = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${backgroundColor('100')};
  z-index: 1;
`;

export const InCallVideoCalled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;

  > img,
  > video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scaleX(-1);
    object-fit: cover;
    height: calc(100%);
    width: calc(100%);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    background: linear-gradient(to bottom, ${rgba(backgroundColor('100'), 1)}, ${rgba(backgroundColor('100'), 0)});
    width: 100%;
    height: 70px;
    z-index: 1;
  }
`;

export const InCallDoorOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: ${successColor};
  border-radius: 31px;
  padding: 18px 24px;
  width: 100%;
  height: 120px;
  margin-bottom: 15px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor('100')};
    border-radius: 50%;
    margin-right: 10px;
    width: 60px;
    height: 60px;

    svg {
      position: relative;
      top: 3px;
    }
  }
`;
