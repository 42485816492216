import Icon from '@shared/components/icons/Icon';
import { SharedButtonLink } from '@styles/shared';
import { backgroundColor, rgba } from '@styles/utils';
import styled from 'styled-components';

export const CheckMessageContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  height: 100%;
`;

export const CheckMessageVideo = styled.div`
  position: relative;
  border-radius: 30px;
  margin-bottom: 50px;
  cursor: pointer;

  > video {
    border-radius: 30px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    background: linear-gradient(to top, ${rgba(backgroundColor('100'), 1)}, ${rgba(backgroundColor('100'), 0)});
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: 100%;
    height: 60px;
  }
`;

export const CheckMessageVideoPlayIcon = styled(Icon)`
  position: absolute;
  bottom: 15px;
  left: calc(50% - 16px);
`;

export const CheckMessageTryAgainLink = styled(SharedButtonLink)`
  margin-top: 25px;
`;
