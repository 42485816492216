import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import Seo from '@shared/modules/seo/Seo';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as Styled from './Page.styles';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageProps {
  title?: string;
  bottom?: BottomBarProps;
  loading?: boolean;
  noPadding?: boolean;
}

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <Styled.PageBottomBar>
        <div>
          <Styled.PageBottomBarLeft>{O.toNullable(leftContent)}</Styled.PageBottomBarLeft>
          <Styled.PageBottomBarRight>{O.toNullable(rightContent)}</Styled.PageBottomBarRight>
        </div>
      </Styled.PageBottomBar>
    );
  }

  return null;
};

const Page: FC<PropsWithChildren<PageProps>> = ({ title, bottom, noPadding, loading, children }) => {
  const isLoading = loading || children == null;

  const seoTitle: string | undefined = pipe(
    O.fromNullable(title),
    O.getOrElse(() => 'QRPhone'),
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      {isLoading && <DebouncedLineLoader />}

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent>
          <Styled.PageChildren $noPadding={noPadding}>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};

export default Page;
