import React, { FC } from 'react';
import { useIdParam } from '@core/router';
import { useLayoutContext } from '@layout/Layout';
import { ResidenceSlug } from '@shared/model/residence';
import { renderConditional } from '@shared/utils/render';
import { Typography } from '@styles/shared';
import { useMatch, useResolvedPath } from 'react-router-dom';

import * as Styled from './Footer.styles';

interface FooterProps {
  hasHeader: boolean;
}

const Footer: FC<FooterProps> = ({ hasHeader }) => {
  const slug = useIdParam<ResidenceSlug>('slug');

  let resolved = useResolvedPath(`${slug}`);
  let match = useMatch({ path: resolved.pathname, end: true });

  const [{ hideFooter }] = useLayoutContext();

  return renderConditional(!hideFooter && hasHeader, () => (
    <Styled.FooterContainer>
      <Styled.FooterButtons>
        <Styled.FooterButtonAnimation $left={!!match} />
        <Styled.FooterListButton to={`/${slug}`} end role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30.062" height="24.056" viewBox="0 0 30.062 24.056">
            <path
              d="M27.177 4a4.885 4.885 0 0 1 4.885 4.885v14.286a4.885 4.885 0 0 1-4.885 4.885H6.885A4.885 4.885 0 0 1 2 23.171V8.885a4.885 4.885 0 0 1 4.608-4.877L6.885 4zm0 2.255H6.885l-.216.009a2.631 2.631 0 0 0-2.414 2.621v14.286a2.63 2.63 0 0 0 2.63 2.63h20.292a2.63 2.63 0 0 0 2.63-2.63V8.885a2.63 2.63 0 0 0-2.63-2.63zM12.9 16.776a1.127 1.127 0 0 1 1.129 1.124v.875c0 1.912-1.456 2.883-3.76 2.883s-3.76-.972-3.76-2.884V17.9a1.127 1.127 0 0 1 1.127-1.127zm2.376 0h2.129a1.127 1.127 0 0 1 1.129 1.124v.5c0 1.448-1.15 2.13-2.818 2.13-.175 0-.343-.008-.506-.023a4.284 4.284 0 0 0 .309-1.352l.013-.381V17.9a2.62 2.62 0 0 0-.254-1.126zm6.638 0h4.51a1.127 1.127 0 0 1 .153 2.244l-.153.01h-4.51a1.127 1.127 0 0 1-.153-2.244l.153-.01zm-11.646-6.012a2.256 2.256 0 1 1-2.256 2.256 2.256 2.256 0 0 1 2.256-2.256zm5.816.755a1.875 1.875 0 1 1-1.876 1.875 1.875 1.875 0 0 1 1.876-1.875zm5.832.748h4.51a1.127 1.127 0 0 1 .153 2.244l-.153.01h-4.51a1.127 1.127 0 0 1-.153-2.244l.153-.01z"
              transform="translate(-2 -4)"
            />
          </svg>
          <Typography $level="navigation" as="span">
            Annuaire
          </Typography>
        </Styled.FooterListButton>
        <Styled.FooterCodeButton to={`/${slug}/code`} role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="26.911" height="24.061" viewBox="0 0 26.911 24.061">
            <g>
              <path
                d="M32.405 0a3.679 3.679 0 1 0 3.679 3.68A3.683 3.683 0 0 0 32.405 0zm0 5.922a2.243 2.243 0 1 1 2.243-2.242 2.246 2.246 0 0 1-2.243 2.243z"
                transform="translate(-28.726 -.001)"
              />
              <path
                d="M134.5 0a3.679 3.679 0 1 0 3.679 3.679A3.683 3.683 0 0 0 134.5 0zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.246 2.246 0 0 1-2.243 2.244z"
                transform="translate(-121.045 -.001)"
              />
              <path
                d="M236.6 7.358a3.679 3.679 0 1 0-3.679-3.679 3.683 3.683 0 0 0 3.679 3.679zm0-5.922a2.243 2.243 0 1 1-2.243 2.243 2.246 2.246 0 0 1 2.243-2.243z"
                transform="translate(-213.364)"
              />
              <path
                d="M32.405 87.215a3.679 3.679 0 1 0 3.679 3.679 3.683 3.683 0 0 0-3.679-3.679zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.246 2.246 0 0 1-2.243 2.243z"
                transform="translate(-28.726 -78.863)"
              />
              <path
                d="M134.5 87.215a3.679 3.679 0 1 0 3.679 3.679 3.683 3.683 0 0 0-3.679-3.679zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.246 2.246 0 0 1-2.243 2.243z"
                transform="translate(-121.045 -78.863)"
              />
              <path
                d="M236.6 87.215a3.679 3.679 0 1 0 3.679 3.679 3.683 3.683 0 0 0-3.679-3.679zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.245 2.245 0 0 1-2.243 2.243z"
                transform="translate(-213.364 -78.863)"
              />
              <path
                d="M32.405 174.429a3.679 3.679 0 1 0 3.679 3.679 3.683 3.683 0 0 0-3.679-3.679zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.246 2.246 0 0 1-2.243 2.243z"
                transform="translate(-28.726 -157.726)"
              />
              <path
                d="M134.5 174.429a3.679 3.679 0 1 0 3.679 3.679 3.683 3.683 0 0 0-3.679-3.679zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.246 2.246 0 0 1-2.243 2.243z"
                transform="translate(-121.045 -157.726)"
              />
              <path
                d="M236.6 174.429a3.679 3.679 0 1 0 3.679 3.679 3.683 3.683 0 0 0-3.679-3.679zm0 5.922a2.243 2.243 0 1 1 2.243-2.243 2.246 2.246 0 0 1-2.243 2.243z"
                transform="translate(-213.364 -157.726)"
              />
            </g>
          </svg>
          <Typography $level="navigation" as="span">
            Code
          </Typography>
        </Styled.FooterCodeButton>
      </Styled.FooterButtons>
    </Styled.FooterContainer>
  ));
};

export default Footer;
