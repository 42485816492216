import { Typography } from '@styles/shared';
import { backgroundColor } from '@styles/utils';
import { columnGap } from '@styles/utils/gap';
import styled, { css } from 'styled-components';

export const ResidentRowContainer = styled.div<{ $unavailable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  background-color: ${backgroundColor('100')};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  padding: 11px 18px 11px 7px;
  margin: 0 22px;
  height: 58px;
  cursor: pointer;

  > div:first-of-type {
    display: flex;
    align-items: center;
    ${columnGap('9px')};

    ${props =>
      props.$unavailable &&
      css`
        opacity: 0.6;
      `}
  }

  p {
    margin: 0;
  }
`;

export const ResidentCompanyName = styled(Typography)`
  opacity: 0.6;
`;

export const ResidentRowNameContainer = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* min-width: 0; */

  > p:first-child {
    margin-right: 4px;
  }
`;
