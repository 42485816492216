import React, { FC } from 'react';
import { Resident } from '@shared/model/resident';
import * as Styled from './Avatar.styles';
import user from '@assets/icons/user/user.svg';
import { renderConditional, renderNullable } from '@shared/utils/render';

interface AvatarProps {
  resident: Resident;
  size?: number;
  className?: string;
}

const Avatar: FC<AvatarProps> = ({ resident, size = 45, className }) => {
  return (
    <Styled.AvatarContainer $size={size} className={className}>
      {renderNullable(
        resident.picture,
        picture => (
          <img src={picture} alt="résident" width={size} />
        ),
        () =>
          renderConditional(
            resident.anonymous,
            () => <img src={user} alt="résident" width={size * 0.4} />,
            () => <span>{`${resident.lastName?.charAt(0) ?? ''}${resident.firstName?.charAt(0) ?? ''}`}</span>,
          ),
      )}
    </Styled.AvatarContainer>
  );
};

export default Avatar;
