import { rowGap } from '@styles/utils/gap';
import styled from 'styled-components';

export const BeforeCallContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${rowGap('15px')};
  height: 100%;
  width: 100%;
  z-index: 1;
`;
