import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import Timer from '@shared/components/timer/Timer';
import { SharedButton, Typography } from '@styles/shared';
import messengerImg from '@assets/mock/videos/caller.jpg';
import * as Styled from './Message.styles';
import { Link } from 'react-router-dom';
import Camera from '@shared/components/camera/Camera';

const Message: FC = () => {
  const page: PageProps = {
    title: 'Laissez un message',
    noPadding: true,
  };

  return (
    <Page {...page}>
      <Styled.MessageContainer>
        <Styled.MessageVideo>
          <Camera fallback={<img src={messengerImg} alt="messagé" />} />
        </Styled.MessageVideo>
        <Styled.MessageContent>
          <Typography $level="standard" aria-live="assertive">
            Enregistrement en cours{' '}
          </Typography>
          <Styled.MessageRecordText>
            <div />
            <Timer />
          </Styled.MessageRecordText>
        </Styled.MessageContent>
        <SharedButton as={Link} to="check" replace btnType="secondary" icon="stop" role="button">
          Terminer
        </SharedButton>
      </Styled.MessageContainer>
    </Page>
  );
};

export default Message;
