import React, { FC, useEffect } from 'react';
import { Residence } from '@shared/model/residence';
import { Route } from 'react-router-dom';
import CodePage from './page/CodePage';
import CodeSuccess from './success/CodeSuccess';
import CodeError from './error/CodeError';
import { Routes } from '@core/router/Routes';
import { useLayoutContext } from '@layout/Layout';

interface CodeProps {
  residence: Residence;
}

const Code: FC<CodeProps> = ({ residence }) => {
  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: false }));
  }, [setProps, residence]);

  return (
    <Routes>
      <Route path="/" element={<CodePage residence={residence} />} />
      <Route path="success" element={<CodeSuccess />} />
      <Route path="error" element={<CodeError residence={residence} />} />
    </Routes>
  );
};

export default Code;
