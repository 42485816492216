import { Resident } from '@shared/model/resident';
import { Typography } from '@styles/shared';
import React, { FC } from 'react';
import * as Styled from './FlatNumber.styles';

interface FlatNumberProps {
  resident: Resident;
}

const FlatNumber: FC<FlatNumberProps> = ({ resident }) => {
  return (
    <Styled.FlatNumberContainer $unavailable={resident.unavailable}>
      <Typography
        as="span"
        $level="name"
        aria-label={resident.unavailable ? 'Indisponible' : `Numéro d'appartement ${resident.flatNumber}`}
        $inverted
      >
        {resident.unavailable ? 'Indisponible' : resident.flatNumber}
      </Typography>
    </Styled.FlatNumberContainer>
  );
};

export default FlatNumber;
