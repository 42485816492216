import { backgroundColor, mediaMax, rgba, tertiaryColor } from '@styles/utils';
import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 4%);

  .field {
    margin: 0 !important;
  }
`;

export const TableContent = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const TableHeader = styled.div`
  display: grid;
  align-items: center;
  padding: 15px;
  background: ${backgroundColor('100')};
  border-bottom: 1px solid ${rgba(tertiaryColor, 0.5)};
  font-size: 12px;
  font-weight: 600;
  color: ${tertiaryColor};
  text-transform: uppercase;
  border-radius: 10px 10px 0 0;

  p {
    margin: 0;
  }
`;

export const TableRow = styled.div`
  display: grid;
  align-items: center;
  padding: 10px 15px;

  p {
    margin: 0;
  }

  background: ${backgroundColor('100')};
  font-size: 14px;
  color: ${tertiaryColor};
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(tertiaryColor, 0.5)};
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  @media screen and (${mediaMax.tablet}) {
    padding: 10px;
  }
`;

export const TableNoRow = styled(TableRow)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  will-change: unset;
`;
