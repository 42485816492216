import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { constVoid } from 'fp-ts/function';

interface CameraProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  onSuccess?: (stream?: MediaStream) => void;
  onFailure?: (err?: any) => void;
  constraints?: MediaStreamConstraints;
  fallback?: ReactNode;
}

const Camera: FC<CameraProps> = ({
  onSuccess = constVoid,
  onFailure = constVoid,
  constraints,
  fallback,
  className,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [hasCamera, setHasCamera] = useState(true);

  useEffect(() => {
    const video = videoRef.current;

    navigator.mediaDevices
      .getUserMedia(constraints ?? { video: { facingMode: 'user' }, audio: false })
      .then(stream => {
        if (video) {
          video.srcObject = stream;
          video.play();
          setHasCamera(true);
          onSuccess(stream);
        }
      })
      .catch(err => {
        setHasCamera(false);
        onFailure(err);
      });

    return () => {
      if (video) {
        const stream = video.srcObject as MediaStream;

        if (stream) {
          const tracks = stream.getTracks();

          for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i];
            track.stop();
          }
        }

        video.srcObject = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, setHasCamera]);

  return hasCamera || !fallback ? (
    <video ref={videoRef} style={{ pointerEvents: 'none' }} muted playsInline {...props} />
  ) : (
    <>{fallback}</>
  );
};

export default Camera;
