import React, { FC, useEffect } from 'react';
import * as Styled from './Calling.style';
import Avatar from '@shared/modules/resident/avatar/Avatar';
import { PageProps } from '@layout/page/Page';
import { SharedButton, Typography } from '@styles/shared';
import FlatNumber from '@shared/modules/resident/flat-number/FlatNumber';
import { Resident } from '@shared/model/resident';
import { CallStatus } from '../model';
import { delay } from '@shared/utils/delay';
import CallButton from '../button/CallButton';
import { renderConditional } from '@shared/utils/render';
import { Link } from 'react-router-dom';
import { useAudioContext } from '@shared/modules/audio/context';

interface CallingProps {
  status: CallStatus;
  resident: Resident;
  updatePageProps: (props: PageProps) => void;
  setStatus: React.Dispatch<React.SetStateAction<CallStatus>>;
}

const Calling: FC<CallingProps> = ({ status, resident, updatePageProps, setStatus }) => {
  useEffect(() => {
    updatePageProps({ title: 'Appel en cours' });
  }, [updatePageProps]);

  const { playAudio, stop } = useAudioContext();

  useEffect(() => {
    if (status !== 'no-response') {
      playAudio('ring');
    }

    if (resident.mockNoResponse) {
      delay(12000).then(() => {
        stop();
        setStatus('no-response');
      });
    } else {
      delay(4000).then(() => {
        stop();
        setStatus('in-call');
      });
    }

    return () => {
      stop();
    };
  }, [setStatus, stop, resident.mockNoResponse, status, playAudio]);

  return (
    <>
      <Styled.CallingContent>
        <Avatar resident={resident} size={92} />
        <p>
          <Typography as="span" $level="name" $inverted>
            {resident.lastName ?? ''}
          </Typography>{' '}
          <Typography as="span" $level="medium" $inverted>
            {resident.firstName ?? ''}
          </Typography>
        </p>
        <FlatNumber resident={resident} />
        {renderConditional(status !== 'no-response', () => (
          <Styled.CallingText $level="standard" aria-live="assertive" $inverted>
            <span>Appel en cours</span>
            <Styled.CallingAnimation />
          </Styled.CallingText>
        ))}
      </Styled.CallingContent>
      {renderConditional(status === 'no-response', () => (
        <Styled.CallingNoResponse aria-live="assertive">
          <Typography $level="h1" as="h1" $inverted>
            Indisponible
          </Typography>
          <SharedButton as={Link} to="message" replace icon="play" role="button">
            Laisser un message
          </SharedButton>
        </Styled.CallingNoResponse>
      ))}
      <CallButton to={status === 'no-response' ? 'message/end' : undefined} />
    </>
  );
};

export default Calling;
