import { Resident, ResidentId } from '@shared/model/resident';
import { MockResidenceSlug } from './residence';
import avatarAntoine from '@assets/mock/avatar-a.png';
import avatarSarah from '@assets/mock/avatar-s.png';
import avatarAlexandre from '@assets/mock/avatar/Alexandre.png';
import avatarChauveton from '@assets/mock/avatar/Chauveton.png';
import avatarCouturier from '@assets/mock/avatar/Couturier.png';
import avatarFeniou from '@assets/mock/avatar/Feniou.png';
import avatarMangiarotta from '@assets/mock/avatar/Mangiarotta.png';
import avatarMoio from '@assets/mock/avatar/Moio.png';
import avatarAllais from '@assets/mock/avatar/Allais.png';
import avatarAllemand from '@assets/mock/avatar/Allemand.png';
import avatarAubay from '@assets/mock/avatar/Aubay.png';
import avatarBlancfuney from '@assets/mock/avatar/Blancfuney.png';
import avatarLigonie from '@assets/mock/avatar/Ligonie.png';
import avatarDosSantos from '@assets/mock/avatar/dossantos.png';
import avatarRaynal from '@assets/mock/avatar/Raynal.png';
import avatarTeule from '@assets/mock/avatar/Teule.png';
import logoCastaing from '@assets/mock/logo-castaing.png';
import logoOrtho from '@assets/mock/logo-ortho.png';

const getResidentName = (resident: Resident): string => {
  let name = '';

  if (resident.lastName) {
    name = resident.lastName;
  } else if (resident.company) {
    name = resident.company;
  } else {
    name = 'Numéro';
  }

  return name;
};

const sortCompare = (a: Resident, b: Resident) => {
  const aName = getResidentName(a);
  const bName = getResidentName(b);

  if (aName < bName) {
    return -1;
  } else if (aName > bName) {
    return 1;
  }

  return 0;
};

export const mockCommonResidents: Array<Resident> = [
  {
    id: '4200' as ResidentId,
    lastName: 'Famille Dupont',
    flatNumber: 'A101',
    mockNoResponse: true,
  },
  {
    id: '4210' as ResidentId,
    flatNumber: 'A103',
    anonymous: true,
    mockNoResponse: true,
  },
  {
    id: '4220' as ResidentId,
    lastName: 'Gauthier',
    firstName: 'Antoine',
    picture: avatarAntoine,
    flatNumber: 'A114',
    floor: 'Étage 5',
  },
  {
    id: '4230' as ResidentId,
    lastName: 'Prévost',
    firstName: 'Samantha',
    flatNumber: 'A108',
    floor: 'RDC',
    mockNoResponse: true,
  },
  {
    id: '4240' as ResidentId,
    company: 'Cabinet Dentaire',
    picture: logoOrtho,
    flatNumber: 'A208',
    unavailable: true,
    mockNoResponse: true,
  },
  {
    id: '4250' as ResidentId,
    lastName: 'Tessier',
    firstName: 'Jean-Marc',
    company: 'Avocat à la Cour',
    flatNumber: 'A301',
    mockNoResponse: true,
  },
  {
    id: '4260' as ResidentId,
    company: 'Sarl Castaing',
    picture: logoCastaing,
    flatNumber: 'A254',
    mockNoResponse: true,
  },
  {
    id: '4270' as ResidentId,
    lastName: 'Noel',
    firstName: 'Sarah',
    picture: avatarSarah,
    flatNumber: 'A225',
    mockNoResponse: true,
  },
];

export const mockDomofranceResidents: Array<Resident> = [
  {
    id: '4280' as ResidentId,
    lastName: 'Chauveton',
    firstName: 'Olivier',
    flatNumber: 'A102',
    picture: avatarChauveton,
    mockNoResponse: true,
  },
  {
    id: '4290' as ResidentId,
    lastName: 'Teulé',
    firstName: 'Sylvain',
    flatNumber: 'A115',
    picture: avatarTeule,
    mockNoResponse: true,
  },
  {
    id: '4300' as ResidentId,
    lastName: 'Gaillard',
    firstName: 'Bertrand ',
    flatNumber: 'A210',
    mockNoResponse: true,
  },
  {
    id: '4310' as ResidentId,
    lastName: 'Mangiarotta',
    firstName: 'Eric',
    flatNumber: 'A310',
    picture: avatarMangiarotta,
    mockNoResponse: true,
  },
  {
    id: '4320' as ResidentId,
    lastName: 'Couturier',
    firstName: 'Benjamin',
    flatNumber: 'A312',
    picture: avatarCouturier,
    mockNoResponse: true,
  },
  ...mockCommonResidents,
].sort(sortCompare);

export const mockClairsienneResidents: Array<Resident> = [
  {
    id: '4280' as ResidentId,
    lastName: 'Richard',
    firstName: 'Laurent',
    flatNumber: 'A102',
    mockNoResponse: true,
  },
  {
    id: '4290' as ResidentId,
    lastName: 'Allais',
    firstName: 'Fabien',
    flatNumber: 'A115',
    picture: avatarAllais,
    mockNoResponse: true,
  },
  {
    id: '4300' as ResidentId,
    lastName: 'Allemand',
    firstName: 'Vincent',
    flatNumber: 'A210',
    picture: avatarAllemand,
    mockNoResponse: true,
  },
  {
    id: '4310' as ResidentId,
    lastName: 'Lelais',
    firstName: 'Ludovic',
    flatNumber: 'A310',
    mockNoResponse: true,
  },
  ...mockCommonResidents,
].sort(sortCompare);

export const mockBelinResidents: Array<Resident> = [
  {
    id: '4280' as ResidentId,
    lastName: 'Cirelli',
    firstName: 'Romuald',
    flatNumber: 'A102',
    mockNoResponse: true,
  },
  {
    id: '4290' as ResidentId,
    lastName: 'Moio',
    firstName: 'Alexandre',
    flatNumber: 'A115',
    picture: avatarMoio,
    mockNoResponse: true,
  },
  {
    id: '4300' as ResidentId,
    lastName: 'Alexandre',
    firstName: 'Manuela ',
    flatNumber: 'A210',
    picture: avatarAlexandre,
    mockNoResponse: true,
  },
  {
    id: '4310' as ResidentId,
    lastName: 'Kerviche',
    firstName: 'Sylvain',
    flatNumber: 'A310',
    mockNoResponse: true,
  },
  {
    id: '4320' as ResidentId,
    lastName: 'Feniou',
    firstName: 'Marjorie',
    flatNumber: 'A312',
    picture: avatarFeniou,
    mockNoResponse: true,
  },
  {
    id: '4330' as ResidentId,
    lastName: 'Ducos',
    firstName: 'Stéphanie',
    flatNumber: 'A401',
    mockNoResponse: true,
  },
  ...mockCommonResidents,
].sort(sortCompare);

export const mockGreenCityImmoResidents: Array<Resident> = [
  {
    id: '4280' as ResidentId,
    lastName: 'Dos Santos',
    firstName: 'Frédéric',
    flatNumber: 'A101',
    picture: avatarDosSantos,
    mockNoResponse: true,
  },
  {
    id: '4290' as ResidentId,
    lastName: 'Raynal',
    firstName: 'Coralie',
    flatNumber: 'A102',
    picture: avatarRaynal,
    mockNoResponse: true,
  },
  {
    id: '4300' as ResidentId,
    lastName: 'Ligonie',
    firstName: 'Romain',
    flatNumber: 'A103',
    picture: avatarLigonie,
    mockNoResponse: true,
  },
  {
    id: '4310' as ResidentId,
    lastName: 'Phelipeau',
    firstName: 'Julien',
    flatNumber: 'A104',
    mockNoResponse: true,
  },
  {
    id: '4320' as ResidentId,
    lastName: 'Aubay',
    firstName: 'Stéphane',
    flatNumber: 'A105',
    picture: avatarAubay,
    mockNoResponse: true,
  },
  {
    id: '4330' as ResidentId,
    lastName: 'Sanson',
    firstName: 'Harmonie',
    flatNumber: 'A106',
    mockNoResponse: true,
  },
  {
    id: '4340' as ResidentId,
    lastName: 'Blancfuney',
    firstName: 'Alexandra',
    flatNumber: 'A107',
    picture: avatarBlancfuney,
    mockNoResponse: true,
  },
  {
    id: '4350' as ResidentId,
    lastName: 'Calmon',
    firstName: 'Lise',
    flatNumber: 'A108',
    mockNoResponse: true,
  },
  ...mockCommonResidents,
].sort(sortCompare);

export const mockPrometheeResidents: Array<Resident> = [...mockCommonResidents].sort(sortCompare);

export const mockAixResidents: Array<Resident> = [...mockCommonResidents].sort(sortCompare);

export const mockEriliaResidents: Array<Resident> = [...mockCommonResidents].sort(sortCompare);

export const mockTdsResidents: Array<Resident> = [
  {
    id: '4280' as ResidentId,
    lastName: 'Famille Pascal',
    flatNumber: 'A102',
    mockNoResponse: true,
  },
  {
    id: '4290' as ResidentId,
    flatNumber: 'A110',
    mockNoResponse: true,
    anonymous: true,
  },
  {
    id: '4300' as ResidentId,
    lastName: 'Boutin',
    firstName: 'Maxime',
    flatNumber: 'A201',
    mockNoResponse: true,
  },
  {
    id: '4310' as ResidentId,
    lastName: 'Joubert',
    firstName: 'Antoine',
    flatNumber: 'A210',
    mockNoResponse: true,
  },
  {
    id: '4320' as ResidentId,
    lastName: 'Beauvais',
    firstName: 'Megane',
    flatNumber: 'A401',
    mockNoResponse: true,
  },
  ...mockCommonResidents,
].sort(sortCompare);

export function getResidentsMock(slug: MockResidenceSlug): Array<Resident> {
  switch (slug) {
    case MockResidenceSlug.Hapiix:
      return mockTdsResidents;
    case MockResidenceSlug.Tds:
      return mockTdsResidents;
    case MockResidenceSlug.Domofrance:
      return mockDomofranceResidents;
    case MockResidenceSlug.Clairsienne:
      return mockClairsienneResidents;
    case MockResidenceSlug.Belin:
      return mockBelinResidents;
    case MockResidenceSlug.GreenCityImmo:
      return mockGreenCityImmoResidents;
    case MockResidenceSlug.Erilia:
      return mockEriliaResidents;
    case MockResidenceSlug.MarieAixEnProvence:
      return mockAixResidents;
    case MockResidenceSlug.Promethee:
      return mockPrometheeResidents;
    default:
      return [];
  }
}
