import queryString, { ParsedQuery, StringifiableRecord } from 'query-string';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { camelToSnake, snakeToCamel } from '@shared/utils/string';

export function queriesToSnakeCase(queries: StringifiableRecord): StringifiableRecord {
  return pipe(
    queries,
    R.reduceWithIndex({} as StringifiableRecord, (key, acc, value) => ({
      ...acc,
      [camelToSnake(key)]: value,
    })),
  );
}

export function queriesToCamelCase(queries: ParsedQuery): ParsedQuery {
  return pipe(
    queries,
    R.reduceWithIndex({} as ParsedQuery, (key, acc, value) => ({
      ...acc,
      [snakeToCamel(key)]: value,
    })),
  );
}

export function stringifyQueries(queries: StringifiableRecord): string {
  return queryString.stringify(queries, { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' });
}

export function parseQueries(queries: string): ParsedQuery {
  return queryString.parse(queries, { arrayFormat: 'comma' });
}
