import { shouldForwardPropHelper } from '@styles/utils';
import styled, { css } from 'styled-components';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VirtualizedTableContent = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const VirtualizedTableRowWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    shouldForwardPropHelper(prop, defaultValidatorFn, ['loading', 'hover']),
})<{ loading?: boolean; hover?: boolean }>`
  ${props =>
    props.loading &&
    css`
      padding: 0 15px;
    `}

  ${props =>
    props.hover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const VirtualizedTableNoRow = styled(VirtualizedTableRowWrapper)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  will-change: unset;
`;
