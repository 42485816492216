import React, { FC } from 'react';
import { Resident } from '@shared/model/resident';
import { Typography } from '@styles/shared';
import * as Styled from './ResidentRow.styles';
import { DivFlex } from '@styles/utils/utils';
import Avatar from '@shared/modules/resident/avatar/Avatar';
import { renderConditional } from '@shared/utils/render';
import FlatNumber from '@shared/modules/resident/flat-number/FlatNumber';

interface ResidentRowProps {
  resident: Resident;
}

const ResidentRow: FC<ResidentRowProps> = ({ resident }) => {
  return (
    <Styled.ResidentRowContainer $unavailable={resident.unavailable} role="button">
      <div>
        <Avatar resident={resident} size={45} />
        <div>
          <DivFlex $gap="4px">
            {renderConditional(
              resident.anonymous,
              () => (
                <Typography as="span" $level="name">
                  Numéro
                </Typography>
              ),
              () =>
                renderConditional(
                  !resident.lastName && !resident.firstName,
                  () => (
                    <Styled.ResidentRowNameContainer>
                      <Typography as="span" $level="name">
                        {resident.company}
                      </Typography>
                    </Styled.ResidentRowNameContainer>
                  ),
                  () => (
                    <Styled.ResidentRowNameContainer>
                      <Typography as="span" $level="name">
                        {resident.lastName}{' '}
                      </Typography>
                      <Typography as="span" $level="medium">
                        {resident.firstName}
                      </Typography>
                    </Styled.ResidentRowNameContainer>
                  ),
                ),
            )}
          </DivFlex>
          {renderConditional(!!resident.lastName || !!resident.firstName, () => (
            <Styled.ResidentRowNameContainer>
              <Styled.ResidentCompanyName as="span" $level="medium" aria-label={`Entreprise ${resident.company}`}>
                {resident.company}
              </Styled.ResidentCompanyName>
            </Styled.ResidentRowNameContainer>
          ))}
        </div>
      </div>
      <FlatNumber resident={resident} />
    </Styled.ResidentRowContainer>
  );
};

export default ResidentRow;
