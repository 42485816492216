import { Residence, ResidenceId, ResidenceSlug } from '@shared/model/residence';
import gciLogo from '@assets/mock/logo-gci.png';
import aixLogo from '@assets/mock/logo-aix.png';
import belinLogo from '@assets/mock/logo-belin.png';
import clairLogo from '@assets/mock/logo-clair.png';
import promeLogo from '@assets/mock/logo-prome.png';
import domoLogo from '@assets/mock/logo-domo.png';
import erLogo from '@assets/mock/logo-er.png';
import hapiixLogo from '@assets/mock/logo-hapiix.png';

export enum MockResidenceSlug {
  Domofrance = 'domofrance',
  Clairsienne = 'clairsienne',
  Belin = 'belinpromotion',
  GreenCityImmo = 'greencityimmobilier',
  Promethee = 'prometheepromotion',
  MarieAixEnProvence = 'mairie-aixenprovence',
  Erilia = 'erilia',
  Tds = 'tds',
  Hapiix = 'hapiix',
}

export const mockTdsResidence: Residence = {
  id: '4100' as ResidenceId,
  slug: MockResidenceSlug.Tds as ResidenceSlug,
  clientName: 'Hapiix',
  residenceName: 'Les Abeilles',
  building: 'Bâtiment A',
  logo: hapiixLogo,
  primaryColor: '#167E59',
  secondaryColor: '#167E59',
  tertiaryColor: '#d2d7e0',
  backgroundColor: '#ffffff',
  code: '4455',
};

export const mockHapiixResidence: Residence = {
  id: '4110' as ResidenceId,
  slug: MockResidenceSlug.Hapiix as ResidenceSlug,
  clientName: 'Hapiix',
  residenceName: 'Les Abeilles',
  building: 'Bâtiment A',
  logo: hapiixLogo,
  primaryColor: '#167E59',
  secondaryColor: '#167E59',
  tertiaryColor: '#d2d7e0',
  backgroundColor: '#ffffff',
  code: '4455',
};

export const mockDomofranceResidence: Residence = {
  id: '4200' as ResidenceId,
  slug: MockResidenceSlug.Domofrance as ResidenceSlug,
  clientName: 'Domofrance',
  residenceName: 'Macédo',
  building: 'Entrée 12',
  logo: domoLogo,
  primaryColor: '#e5084c',
  secondaryColor: '#143a68',
  tertiaryColor: '#f2f2f2',
  backgroundColor: '#ffff',
  code: '123456',
};

export const mockClairsienneResidence: Residence = {
  id: '4210' as ResidenceId,
  slug: MockResidenceSlug.Clairsienne as ResidenceSlug,
  clientName: 'Clairsienne',
  residenceName: "Les Jardins de l'Hermitage",
  building: 'Bâtiment A',
  logo: clairLogo,
  primaryColor: '#e5084c',
  secondaryColor: '#143a68',
  tertiaryColor: '#f2f2f2',
  backgroundColor: '#ffff',
  code: '123456',
};

export const mockBelinResidence: Residence = {
  id: '4220' as ResidenceId,
  slug: MockResidenceSlug.Belin as ResidenceSlug,
  clientName: 'Belin Promotion',
  residenceName: 'Komorebi',
  building: 'Bâtiment C',
  logo: belinLogo,
  primaryColor: '#064843',
  secondaryColor: '#5c8e8f',
  tertiaryColor: '#fcdcc5',
  backgroundColor: '#064843',
  code: '123456',
};

export const mockGreenCityImmoResidence: Residence = {
  id: '4230' as ResidenceId,
  slug: MockResidenceSlug.GreenCityImmo as ResidenceSlug,
  clientName: 'GreenCity Immo',
  residenceName: 'Carré Flore',
  building: 'Bâtiment A',
  logo: gciLogo,
  primaryColor: '#1b2f4d',
  secondaryColor: '#bd9f69',
  tertiaryColor: '#456270',
  backgroundColor: '#1b2f4d',
  code: '123456',
};

export const mockPrometheeResidence: Residence = {
  id: '4240' as ResidenceId,
  slug: MockResidenceSlug.Promethee as ResidenceSlug,
  clientName: 'Promethée promotiono',
  residenceName: 'Domaine de la torse',
  building: 'Bâtiment A',
  logo: promeLogo,
  primaryColor: '#1d3c67',
  secondaryColor: '#0c1e38',
  tertiaryColor: '#bcc6d1',
  backgroundColor: '#ffff',
  code: '123456',
};

export const mockAixResidence: Residence = {
  id: '4250' as ResidenceId,
  slug: MockResidenceSlug.MarieAixEnProvence as ResidenceSlug,
  clientName: 'Mairie de Aix-en-provence',
  residenceName: 'Service technique',
  logo: aixLogo,
  primaryColor: '#cca538',
  secondaryColor: '#bc312f',
  tertiaryColor: '#d1e5ff',
  backgroundColor: '#ffff',
  code: '123456',
};

export const mockEriliaResidence: Residence = {
  id: '4260' as ResidenceId,
  slug: MockResidenceSlug.Erilia as ResidenceSlug,
  clientName: 'Erilia',
  residenceName: 'Résidence',
  logo: erLogo,
  primaryColor: '#e2001a',
  secondaryColor: '#bd1d2d',
  tertiaryColor: '#e7e5e6',
  backgroundColor: '#e2001a',
  code: '123456',
};

export function getResidenceMock(slug: MockResidenceSlug): Residence | null {
  switch (slug) {
    case MockResidenceSlug.Tds:
      return mockTdsResidence;
    case MockResidenceSlug.Hapiix:
      return mockHapiixResidence;
    case MockResidenceSlug.Domofrance:
      return mockDomofranceResidence;
    case MockResidenceSlug.Clairsienne:
      return mockClairsienneResidence;
    case MockResidenceSlug.Belin:
      return mockBelinResidence;
    case MockResidenceSlug.GreenCityImmo:
      return mockGreenCityImmoResidence;
    case MockResidenceSlug.Erilia:
      return mockEriliaResidence;
    case MockResidenceSlug.MarieAixEnProvence:
      return mockAixResidence;
    case MockResidenceSlug.Promethee:
      return mockPrometheeResidence;
    default:
      return null;
  }
}
