import { HttpError, HttpTask } from '@core/http';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { Residence } from '@shared/model/residence';

export const getResidenceEntance = (residence: Residence, code: string): HttpTask => {
  return pipe(
    code,
    O.fromPredicate(code => code === residence.code),
    TE.fromOption(() => HttpError.notFound),
  );
};
