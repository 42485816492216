import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { Residence } from '@shared/model/residence';
import { pipe } from 'fp-ts/function';
import * as CodeService from '../service';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';

import * as Styled from './CodePage.styles';
import cancelIcon from '@assets/icons/code/cancel.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLayoutContext } from '@layout/Layout';
import { useAudioContext } from '@shared/modules/audio/context';

interface CodePageProps {
  residence: Residence;
}

const CodePage: FC<CodePageProps> = ({ residence }) => {
  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: false }));
  }, [setProps]);

  const [code, setCode] = useState('');

  const navigate = useNavigate();

  const location = useLocation();

  const buttonDiv = useRef<HTMLDivElement>(null);

  const width = useMemo(() => {
    const divHeight = buttonDiv.current?.clientHeight ?? 110;
    const divWidth = buttonDiv.current?.clientWidth ?? 110;

    if (divWidth < divHeight) {
      return divWidth;
    } else {
      return divHeight;
    }
  }, [buttonDiv]);

  const { playAudio } = useAudioContext();

  const checkCode = (currentCode: string) => {
    if (currentCode.length === residence.code.length) {
      playAudio('init');

      pipe(
        CodeService.getResidenceEntance(residence, currentCode),
        T.chainIOK(res => () => {
          if (E.isRight(res)) {
            navigate(`${location.pathname}/success`);
          } else {
            navigate(`${location.pathname}/error`);
          }
        }),
      )();
    }
  };

  const handleChangeInputCode = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setCode(value);

    checkCode(value);
  };

  const handleChangeCodeButton = (value: string | null) => () => {
    if (!value) {
      const newCode = code.slice(0, -1);

      setCode(newCode);

      checkCode(newCode);
    } else {
      const newCode = `${code}${value}`;

      setCode(newCode);

      checkCode(newCode);
    }
  };

  const pageProps: PageProps = {
    title: 'Code résidence',
  };

  return (
    <Page {...pageProps}>
      <Styled.CodePageContainer>
        <Styled.CodeInputContainer>
          <input
            value={code}
            onChange={handleChangeInputCode}
            pattern="[0-9#]*"
            inputMode="numeric"
            placeholder="Composez votre code d'accès"
          />
        </Styled.CodeInputContainer>
        <Styled.CodeContainer $width={width}>
          <div ref={buttonDiv}>
            <button onClick={handleChangeCodeButton('1')}>1</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('2')}>2</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('3')}>3</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('4')}>4</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('5')}>5</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('6')}>6</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('7')}>7</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('8')}>8</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('9')}>9</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('#')}>#</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton('0')}>0</button>
          </div>
          <div>
            <button onClick={handleChangeCodeButton(null)}>
              <Styled.CodeCancelButtonIcon src={cancelIcon} alt="annuler" />
            </button>
          </div>
        </Styled.CodeContainer>
      </Styled.CodePageContainer>
    </Page>
  );
};

export default CodePage;
