import React, { FC, useEffect } from 'react';
import * as Styled from './BeforeCall.styles';
import Avatar from '@shared/modules/resident/avatar/Avatar';
import { PageProps } from '@layout/page/Page';
import { Typography } from '@styles/shared';
import FlatNumber from '@shared/modules/resident/flat-number/FlatNumber';
import { Resident } from '@shared/model/resident';
import { CallStatus } from '../model';
import CallButton from '../button/CallButton';

interface BeforeCallProps {
  resident: Resident;
  updatePageProps: (props: PageProps) => void;
  setStatus: React.Dispatch<React.SetStateAction<CallStatus>>;
}

const BeforeCall: FC<BeforeCallProps> = ({ resident, updatePageProps, setStatus }) => {
  useEffect(() => {
    updatePageProps({ title: 'Préparation appel' });
  }, [updatePageProps]);

  return (
    <>
      <Styled.BeforeCallContent>
        <Avatar resident={resident} size={186} />
        <p>
          <Typography as="span" $level="large">
            {resident.lastName ?? ''}
          </Typography>{' '}
          <Typography as="span" $level="smallLarge">
            {resident.firstName ?? ''}
          </Typography>
        </p>
        <FlatNumber resident={resident} />
      </Styled.BeforeCallContent>
      <CallButton />
    </>
  );
};

export default BeforeCall;
