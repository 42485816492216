import React, { FC, useEffect } from 'react';
import errorAnimation from '@assets/animations/error.json';
import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import * as Styled from './CodeError.styles';
import { Typography } from '@styles/shared';
import { Link } from 'react-router-dom';
import { Residence } from '@shared/model/residence';
import Page, { PageProps } from '@layout/page/Page';
import { useLayoutContext } from '@layout/Layout';
import { useAudioContext } from '@shared/modules/audio/context';

interface CodeErrorProps {
  residence: Residence;
}

const CodeError: FC<CodeErrorProps> = ({ residence }) => {
  const [, setProps] = useLayoutContext();

  const { playAudio } = useAudioContext();

  useEffect(() => {
    playAudio('door-closed');
  }, [playAudio]);

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: true }));
  }, [setProps]);

  const pageProps: PageProps = {
    title: 'Erreur code porte',
  };

  return (
    <Page {...pageProps}>
      <Styled.CodeErrorContainer aria-live="assertive">
        <Styled.CodeErrorContent>
          <AnimatedIcon animationData={errorAnimation} style={{ width: 160 }} settings={{ loop: false }} />
          <Typography $level="h1" as="h1">
            Code incorrect
          </Typography>
          <Typography $level="standard">Vous pouvez fermer l'onglet</Typography>
          <Styled.CodeErrorButton as={Link} to={`/${residence.slug}/code`}>
            Réessayer
          </Styled.CodeErrorButton>
        </Styled.CodeErrorContent>
        <Typography $level="standard">A bientôt !</Typography>
      </Styled.CodeErrorContainer>
    </Page>
  );
};

export default CodeError;
