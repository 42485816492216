import React, { PureComponent, ReactNode } from 'react';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  KeyMapper,
  List,
  ListRowProps,
  WindowScroller,
} from 'react-virtualized';

import * as Styled from './VirtualizedTable.styles';

import * as O from 'fp-ts/Option';
import { Link } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import { sequenceT } from 'fp-ts/Apply';

import isEqual from 'lodash.isequal';
import { DEFAULT_RANGE_SIZE, VirtualizedTableProps } from '@shared/modules/range';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { renderOptional } from '@shared/utils/render';
import { PAGE_SCROLLER_ID } from '@layout/page/Page';
import Sticky from 'react-sticky-el';

const MIN_ROW_HEIGHT = 65;

export class VirtualizedTable<T> extends PureComponent<VirtualizedTableProps<T>> {
  private readonly cache: CellMeasurerCache;

  private scrollElement: HTMLElement | null = null;

  private listRef: List | null = null;

  constructor(props: VirtualizedTableProps<T>) {
    super(props);

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: MIN_ROW_HEIGHT,
      keyMapper: this.cellMeasurerCacheKeyMapper,
    });
  }

  componentDidMount() {
    this.scrollElement = document.getElementById(PAGE_SCROLLER_ID);

    if (this.props.noFilter) {
      this.props.loadMore({});
    }
  }

  componentDidUpdate(prevProps: Readonly<VirtualizedTableProps<T>>) {
    if (!isEqual(prevProps.range.filter, this.props.range.filter)) {
      document.getElementById(PAGE_SCROLLER_ID)?.scrollTo({ top: 0 });
    }

    if (prevProps.range.items !== this.props.range.items) {
      this.cache.clearAll();
      this.listRef?.forceUpdateGrid();
    }
  }

  private cellMeasurerCacheKeyMapper: KeyMapper = (rowIndex: number, columnIndex: number) =>
    pipe(
      O.fromNullable(this.props.keyMapper),
      O.chain(keyMapper =>
        pipe(
          this.props.range.get(rowIndex),
          O.map(item => keyMapper(item, rowIndex)),
        ),
      ),
      O.getOrElse(() => `${rowIndex}-${columnIndex}-${this.props.range.has(rowIndex) ? 'loaded' : 'loading'}`),
    );

  private clearCellMeasurerCache = () => this.cache.clearAll();

  private isRowLoaded = ({ index }: Index): boolean => this.props.range.has(index);

  private loadMoreRows = ({ startIndex, stopIndex }: IndexRange) =>
    this.props.loadMore({ startIndex, endIndex: stopIndex });

  private rowRenderer = (props: ListRowProps): ReactNode => {
    const { range, rowLinkBuilder, onRowClick } = this.props;

    const item = range.get(props.index);

    const to = pipe(
      sequenceT(O.option)(item, O.fromNullable(rowLinkBuilder)),
      O.chainNullableK(([item, rowLinkBuilder]) => rowLinkBuilder(item, props.index)),
      O.toNullable,
    );

    const handleClick = () => {
      if (O.isSome(item) && onRowClick) {
        onRowClick(item.value, props.index);
      }
    };

    return (
      <CellMeasurer key={props.key} cache={this.cache} columnIndex={0} rowIndex={props.index} parent={props.parent}>
        {({ registerChild }) => (
          <Styled.VirtualizedTableRowWrapper
            as={to ? Link : undefined}
            to={to ?? ''}
            ref={registerChild as any}
            style={props.style}
            loading={O.isNone(item)}
            hover={O.isSome(item) && !!(to || onRowClick)}
            onClick={handleClick}
          >
            {renderOptional(item, item => this.props.children(item, props.index))}
          </Styled.VirtualizedTableRowWrapper>
        )}
      </CellMeasurer>
    );
  };

  private noRowsRenderer = () =>
    this.props.range.loading ? (
      <></>
    ) : (
      <Styled.VirtualizedTableNoRow>
        <p>{this.props.emptyMessage ?? 'Aucune donnée à afficher.'}</p>
      </Styled.VirtualizedTableNoRow>
    );

  private registerListRef = (infiniteLoaderRegister: (ref: List | null) => void) => (ref: List | null) => {
    infiniteLoaderRegister(ref);
    this.listRef = ref;
  };

  render() {
    const { range, header, noFilter } = this.props;

    return (
      <Styled.VirtualizedTableContainer>
        <Sticky
          scrollElement={document.getElementById(PAGE_SCROLLER_ID) ?? (undefined as any)}
          topOffset={noFilter ? 0 : -60}
          stickyStyle={{ zIndex: 100, marginTop: noFilter ? 0 : 60 }}
        >
          {header}
        </Sticky>

        <Styled.VirtualizedTableContent>
          {range.loading && <DebouncedLineLoader />}
          <InfiniteLoader
            rowCount={range.total}
            loadMoreRows={this.loadMoreRows}
            isRowLoaded={this.isRowLoaded}
            minimumBatchSize={DEFAULT_RANGE_SIZE}
            threshold={20}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller scrollElement={this.scrollElement ?? window}>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <AutoSizer onResize={this.clearCellMeasurerCache} disableHeight>
                    {({ width }) => (
                      <List
                        ref={this.registerListRef(registerChild)}
                        deferredMeasurementCache={this.cache}
                        autoHeight
                        rowCount={range.total}
                        rowHeight={this.cache.rowHeight}
                        estimatedRowSize={MIN_ROW_HEIGHT}
                        width={width}
                        height={height}
                        onRowsRendered={onRowsRendered}
                        onScroll={onChildScroll}
                        noRowsRenderer={this.noRowsRenderer}
                        rowRenderer={this.rowRenderer}
                        isScrolling={isScrolling}
                        scrollTop={scrollTop}
                        style={{ outline: 'none', willChange: range.total === 0 ? 'unset' : 'transform' }}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        </Styled.VirtualizedTableContent>
      </Styled.VirtualizedTableContainer>
    );
  }
}
