import React, { FC, Dispatch, SetStateAction, useEffect, createContext, useMemo, useState, useContext } from 'react';
import { useIdParam } from '@core/router';
import { Residence, ResidenceSlug } from '@shared/model/residence';
import * as ResidentService from './service';
import { useFetchTask } from '@core/http/hooks';
import { renderHttpRemoteData } from '@shared/utils/render';
import { useLayoutContext } from '@layout/Layout';
import { Resident } from '@shared/model/resident';
import { constVoid } from 'fp-ts/function';
import { Routes } from '@core/router/Routes';
import { Route } from 'react-router-dom';
import ListPage from './residents/ListPage';
import Code from './code/Code';
import CallRoutes from './call/routes';
import { AudioContextProvider } from '@shared/modules/audio/context';

interface HomePageProps {
  residence: Residence;
}

export interface ResidentContextProps {
  resident?: Resident;
}

type ResidentContextValue = [
  resident: ResidentContextProps,
  setResident: Dispatch<SetStateAction<ResidentContextProps>>,
];

const ResidentContext = createContext<ResidentContextValue>([{}, constVoid]);

const AppRoutesPage: FC<HomePageProps> = ({ residence }) => {
  const [resident, setResident] = useState<ResidentContextProps>(() => ({}));

  const ctx = useMemo<ResidentContextValue>(() => [resident, setResident], [resident]);

  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, residence: residence }));
  }, [setProps, residence]);

  return (
    <AudioContextProvider>
      <ResidentContext.Provider value={ctx}>
        <Routes>
          <Route path="/" element={<ListPage residence={residence} />} />
          <Route path="code/*" element={<Code residence={residence} />} />
          {!!resident.resident && <Route path="call/*" element={<CallRoutes />} />}
        </Routes>
      </ResidentContext.Provider>
    </AudioContextProvider>
  );
};

export function useResidentContext() {
  return useContext(ResidentContext);
}

const AppRoutes: FC = () => {
  const slug = useIdParam<ResidenceSlug>('slug');

  const [residence] = useFetchTask(ResidentService.getResidence, slug);

  return renderHttpRemoteData(residence, residence => <AppRoutesPage residence={residence} />);
};

export default AppRoutes;
