import React, { FC } from 'react';
import { useLayoutContext } from '@layout/Layout';
import { renderNullable } from '@shared/utils/render';
import { Typography } from '@styles/shared';
import * as Styled from './Header.styles';
import { Link } from 'react-router-dom';
import { useIdParam } from '@core/router';
import { ResidenceSlug } from '@shared/model/residence';

const Header: FC = () => {
  const [{ residence }] = useLayoutContext();

  const [{ hideHeader }] = useLayoutContext();

  const slug = useIdParam<ResidenceSlug>('slug');

  return !hideHeader
    ? renderNullable(residence, residence => (
        <Styled.HeaderContainer id="header" as={Link} to={`/${slug}`}>
          {renderNullable(residence?.logo, logo => (
            <img src={logo} alt={`logo résidence ${residence.clientName}`} height={83} />
          ))}
          <Styled.HeaderInfos>
            <Typography $level="standard">{residence?.residenceName}</Typography>
            <p>{residence?.building}</p>
          </Styled.HeaderInfos>
        </Styled.HeaderContainer>
      ))
    : null;
};

export default Header;
