import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import { Typography } from '@styles/shared';
import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';
import Avatar from '@shared/modules/resident/avatar/Avatar';

export const EndCallContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 15px 20px;
  height: 100%;
  width: 100%;
`;

export const EndCallAnimation = styled(AnimatedIcon)``;

export const EndCallContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-70px);
  flex: 1 1 auto;
  width: 100%;
`;

export const EndCallTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-70px);
  width: 100%;
`;

export const EndCallInfos = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 15px;
    }
  }
`;

export const EndCallResidentInfos = styled.div`
  position: relative;
  padding: 30px;
  margin-top: 30px;
  background: ${backgroundColor()};
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  text-align: center;
`;

export const EndCallResidentAvatar = styled(Avatar)`
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
`;
