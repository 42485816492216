import { rgba, secondaryColor } from '@styles/utils';
import styled, { css } from 'styled-components';

export const FlatNumberContainer = styled.div<{ $unavailable?: boolean }>`
  background-color: ${secondaryColor};
  padding: 1px 8px;
  border-radius: 17px;

  ${props =>
    props.$unavailable &&
    css`
      background-color: ${rgba(secondaryColor, 0.26)};

      span {
        color: ${secondaryColor};
      }
    `}
`;
