import { HttpError, HttpTask } from '@core/http';
import { Resident } from '@shared/model/resident';
import * as TE from 'fp-ts/TaskEither';
import { getResidenceMock, MockResidenceSlug } from 'mock/residence';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { getResidentsMock } from 'mock/resident';
import { Residence, ResidenceSlug } from '@shared/model/residence';
import { SearchFilter } from '@shared/modules/filter';
import { Range, RangeCursor } from '@shared/modules/range';

export const getResidence = (slug: ResidenceSlug): HttpTask<Residence> => {
  return pipe(
    getResidenceMock(slug as MockResidenceSlug),
    O.fromNullable,
    TE.fromOption(() => HttpError.notFound),
  );
};

export const getResidents =
  (slug: ResidenceSlug) =>
  (cursor: RangeCursor, filter?: SearchFilter): HttpTask<Range<Resident, SearchFilter>> => {
    const search = filter?.search?.toLowerCase() ?? '';

    return pipe(
      getResidentsMock(slug as MockResidenceSlug),
      O.fromNullable,
      TE.fromOption(() => HttpError.notFound),
      TE.map(elt =>
        Range.fromArray(
          elt.filter(
            elt =>
              elt.firstName?.toLowerCase().includes(search) ||
              elt.lastName?.toLowerCase().includes(search) ||
              elt.company?.toLowerCase().includes(search) ||
              elt.flatNumber?.toLowerCase().includes(search),
          ),
        ),
      ),
    );
  };
