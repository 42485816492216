import React, { FC, useRef, useState } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import * as Styled from './CheckMessage.styles';
import { SharedButton, Typography } from '@styles/shared';
import { Link } from 'react-router-dom';
import posterImg from '@assets/mock/message/poster.jpg';
import Video, { VideoElement } from '@shared/components/video/Video';

const CheckMessage: FC = () => {
  const videoRef = useRef<VideoElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const pageProps: PageProps = {
    title: 'Votre message',
  };

  const onPlaying = (playing: boolean) => {
    setIsPlaying(playing);
  };

  const playVideo = () => {
    videoRef.current?.playVideo();
  };

  return (
    <Page {...pageProps}>
      <Styled.CheckMessageContainer>
        <Typography $level="large" as="h1" aria-live="assertive">
          Votre message
        </Typography>
        <Styled.CheckMessageVideo onClick={playVideo}>
          <Video ref={videoRef} poster={posterImg} width={150} playsInline onPlaying={onPlaying}>
            <source src="/assets/message.mp4" type="video/mp4" />
          </Video>
          <Styled.CheckMessageVideoPlayIcon type={isPlaying ? 'pause' : 'play-full'} />
        </Styled.CheckMessageVideo>
        <SharedButton as={Link} to="../sent" replace icon="send" role="button">
          Envoyer
        </SharedButton>
        <Styled.CheckMessageTryAgainLink to="..">Recommencer</Styled.CheckMessageTryAgainLink>
      </Styled.CheckMessageContainer>
    </Page>
  );
};

export default CheckMessage;
