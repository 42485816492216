import React, { FC, useEffect, useState } from 'react';
import { PageProps } from '@layout/page/Page';
import { Typography } from '@styles/shared';
import FlatNumber from '@shared/modules/resident/flat-number/FlatNumber';
import { Resident } from '@shared/model/resident';
import { CallStatus } from '../model';
import CallButton from '../button/CallButton';
import * as Styled from './InCall.styles';
import Timer from '../../../../shared/components/timer/Timer';
import { delay } from '@shared/utils/delay';
import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import successAnimation from '@assets/animations/check.json';
import calledImg from '@assets/mock/videos/caller.jpg';
import { renderConditional } from '@shared/utils/render';
import { ScreenReaderOnly } from '@styles/utils/a11y';
import Loader from '@shared/components/loader/Loader';
import { useAudioContext } from '@shared/modules/audio/context';

interface InCallProps {
  resident: Resident;
  status: CallStatus;
  setStatus: React.Dispatch<React.SetStateAction<CallStatus>>;
  updatePageProps: (props: PageProps) => void;
}

const InCall: FC<InCallProps> = ({ resident, status, setStatus, updatePageProps }) => {
  const [doorOpened, setDoorOpened] = useState(false);

  const { playAudio } = useAudioContext();

  useEffect(() => {
    updatePageProps({ title: 'Appel en cours' });
  }, [updatePageProps]);

  const [isPlaying] = useState(true);

  useEffect(() => {
    if (status === 'in-call' && !doorOpened) {
      delay(3000).then(() => {
        setDoorOpened(true);
        setStatus('call-ending');
      });
    }

    if (doorOpened) {
      playAudio('door-open');
      if (resident.mockHangUp) {
        delay(3000).then(() => setStatus('end-call'));
      } else {
        delay(7000).then(() => {
          setDoorOpened(false);
        });
      }
    }
  }, [setDoorOpened, resident.mockHangUp, doorOpened, setStatus, status, playAudio]);

  const endCall = () => {
    setStatus('end-call');
  };

  return (
    <Styled.InCallContainer>
      <Styled.InCallVideoCalled>
        {/* <Video style={{ pointerEvents: 'none' }} onPlaying={onPlaying} playsInline autoPlay>
          <source src="/assets/called.mp4" type="video/mp4" />
        </Video> */}
        <img src={calledImg} alt="appelant" />
        {status === 'in-call' && (
          <>
            {renderConditional(
              !isPlaying,
              () => (
                <Styled.MockInCallVideoNotPlaying>
                  <Loader />
                  <p aria-live="polite">Chargement</p>
                </Styled.MockInCallVideoNotPlaying>
              ),
              () => (
                <ScreenReaderOnly $strict>Communication établie</ScreenReaderOnly>
              ),
            )}
          </>
        )}
      </Styled.InCallVideoCalled>
      <Styled.InCallContent>
        <Styled.InCallTop>
          <div>
            <div>
              <Typography as="span" $level="name">
                {resident.lastName ?? ''}
              </Typography>{' '}
              <Typography as="span" $level="medium">
                {resident.firstName ?? ''}
              </Typography>
              <Timer />
            </div>
            <div style={{ display: 'inline-flex' }}>
              <FlatNumber resident={resident} />
            </div>
          </div>
        </Styled.InCallTop>
      </Styled.InCallContent>
      <Styled.InCallBottom>
        {doorOpened && (
          <Styled.InCallDoorOpen>
            <div>
              <AnimatedIcon animationData={successAnimation} style={{ width: 80 }} settings={{ loop: false }} />
            </div>
            <Typography $level="large" aria-live="polite" $inverted>
              Porte ouverte !
            </Typography>
          </Styled.InCallDoorOpen>
        )}
        <CallButton onClick={status !== 'in-call' ? endCall : undefined} />
      </Styled.InCallBottom>
    </Styled.InCallContainer>
  );
};

export default InCall;
