import { useCallback, useEffect, useMemo } from 'react';

interface AudioOptions {
  volume?: number;
  loop?: boolean;
}

export default function useAudio(src: string, { volume = 1, loop = false }: AudioOptions) {
  const audio = useMemo(() => new Audio(src), [src]);

  const play = () => {
    audio.play();
  };

  const pause = () => {
    audio.pause();
  };

  const setCurrentTime = (time: number) => {
    audio.currentTime = time;
  };

  const replay = () => {
    stop();
    audio.loop = loop;
    audio.play();
  };

  const stop = useCallback(() => {
    audio.pause();
    audio.currentTime = 0;
    audio.loop = false;
  }, [audio]);

  useEffect(() => {
    audio.volume = volume;
    audio.loop = loop;

    return () => {
      stop();
    };
  }, [audio, loop, stop, volume]);

  return { play, pause, stop, setCurrentTime, replay };
}
