import React, { FC, useEffect } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { useRange, VirtualizedTable } from '@shared/modules/range';
import { Resident } from '@shared/model/resident';
import ResidentRow from './row/ResidentRow';
import { useIdParam } from '@core/router';
import { Residence, ResidenceSlug } from '@shared/model/residence';
import * as ResidentService from '../service';
import { defaultSearchFilter, searchFilterParser } from '@shared/modules/filter';
import { Filters } from '@shared/modules/filter/components';
import { useResidentContext } from '../routes';
import { useNavigate } from 'react-router-dom';
import { useLayoutContext } from '@layout/Layout';
import { useAudioContext } from '@shared/modules/audio/context';

interface ListPageProps {
  residence: Residence;
}

const ListPage: FC<ListPageProps> = ({ residence }) => {
  const slug = useIdParam<ResidenceSlug>('slug');

  const navigate = useNavigate();

  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: false }));
  }, [setProps, residence]);

  const [, setResident] = useResidentContext();

  const { range, filter, handleLoadMore, handleFilter } = useRange(
    ResidentService.getResidents(slug),
    defaultSearchFilter,
  );

  const { playAudio } = useAudioContext();

  const onResidentClick = (resident: Resident) => {
    if (!resident.unavailable) {
      setResident({ resident });
      playAudio('init');
      navigate('call');
    }
  };

  const pageProps: PageProps = {
    title: residence.clientName ?? '',
  };

  return (
    <Page {...pageProps}>
      <Filters
        filter={filter}
        defaultFilter={defaultSearchFilter}
        parser={searchFilterParser}
        search
        onFilter={handleFilter}
      />
      <VirtualizedTable range={range} loadMore={handleLoadMore} onRowClick={onResidentClick}>
        {resident => <ResidentRow resident={resident} />}
      </VirtualizedTable>
    </Page>
  );
};

export default ListPage;
