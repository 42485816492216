import styled, { css } from 'styled-components';

import { backgroundColor, primaryColor } from '@styles/utils';
import { ButtonType } from './Button';

export const SharedButtonContainer = styled.button<{ $btnType: ButtonType; $hasIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  border-radius: 41px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  padding: 17px 36px;
  height: 56px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;

  ${props =>
    props.$btnType === 'primary'
      ? css`
          color: ${backgroundColor('100')};
          background-color: ${primaryColor};

          &:hover {
            color: ${backgroundColor('100')};
          }
        `
      : css`
          color: ${backgroundColor('800')};
          background-color: ${backgroundColor('100')};

          &:hover {
            color: ${backgroundColor('800')};
          }
        `};

  ${props =>
    props.$hasIcon &&
    css`
      > span {
        margin-left: 15px;
      }
    `}
`;

export const SharedButtonLinkContainer = styled.button`
  color: ${backgroundColor('800')};
  font-family: HelveticaNeue;
  font-size: 17px;
  font-weight: normal;
  text-decoration: underline;
  border: none;
  outline: none;

  &:hover {
    color: ${backgroundColor('800')};
    text-decoration: underline;
  }
`;
