import React, { FC, useEffect } from 'react';
import { Typography } from '@styles/shared';
import successAnimation from '@assets/animations/check.json';
import * as Styled from './CodeSuccess.styles';
import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import Page, { PageProps } from '@layout/page/Page';
import { useLayoutContext } from '@layout/Layout';
import { useAudioContext } from '@shared/modules/audio/context';

const CodeSuccess: FC = () => {
  const [, setProps] = useLayoutContext();

  const { playAudio } = useAudioContext();

  useEffect(() => {
    playAudio('door-open');
  }, [playAudio]);

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: true }));
  }, [setProps]);

  const pageProps: PageProps = {
    title: 'Porte ouverte',
  };

  return (
    <Page {...pageProps}>
      <Styled.CodeSuccessContainer aria-live="assertive">
        <Styled.CodeSuccessContent>
          <AnimatedIcon animationData={successAnimation} style={{ width: 140 }} settings={{ loop: false }} />
          <Typography $level="h1" as="h1">
            Porte ouverte !
          </Typography>
          <Typography $level="standard">Vous pouvez fermer l'onglet</Typography>
        </Styled.CodeSuccessContent>
        <Typography $level="standard">A bientôt !</Typography>
      </Styled.CodeSuccessContainer>
    </Page>
  );
};

export default CodeSuccess;
