import styled, { css } from 'styled-components';

export const MessageFinishContainer = styled.div<{ $sent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;

  ${props =>
    !props.$sent &&
    css`
      transform: translateY(-50px);
    `}
`;

export const MessageTextContainer = styled.div<{ $sent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    !props.$sent &&
    css`
      transform: translateY(-50px);
    `}

  > h1 {
    margin-top: 0;
  }
`;
