import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import Layout from '@layout/Layout';
import { Routes } from '@core/router/Routes';
import AppRoutes from '@modules/app/routes';

const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/:slug/*" element={<AppRoutes />} />
      </Route>
    </Routes>
  );
};

export default RootRoutes;
