import React, { FC, useEffect } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { Link } from 'react-router-dom';
import { useIdParam } from '@core/router';
import { ResidenceSlug } from '@shared/model/residence';
import { useLayoutContext } from '@layout/Layout';
import { SharedButton } from '@styles/shared';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  const slug = useIdParam<ResidenceSlug>('slug');

  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: false, hideFooter: true }));
  }, [setProps]);

  return (
    <Styled.ErrorPageContainer aria-live="polite">
      <div>
        <h1>
          {HttpStatusCode.NOT_FOUND === error.status
            ? 'La page que vous cherchez est introuvable'
            : `Une erreur ${error.status} est survenue`}
        </h1>
      </div>

      {slug && (
        <>
          <p>Nous vous invitons à revenir à l'accueil</p>

          <SharedButton as={Link} to={`/${slug}`} btnType="secondary">
            Revenir à l'accueil
          </SharedButton>
        </>
      )}
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
