import { backgroundColor, errorColor, rgba } from '@styles/utils';
import styled from 'styled-components';

export const MessageContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  height: 100%;
  z-index: 1;
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const MessageRecordText = styled.div`
  display: flex;
  align-items: center;

  > div:first-of-type {
    background-color: ${errorColor};
    border-radius: 50%;
    margin-right: 5px;
    height: 7px;
    width: 7px;
  }
`;

export const MessageVideo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;

  > img,
  > video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scaleX(-1);
    object-fit: cover;
    height: calc(100%);
    width: calc(100%);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    background: linear-gradient(to bottom, ${rgba(backgroundColor('100'), 1)}, ${rgba(backgroundColor('100'), 0)});
    width: 100%;
    height: 120px;
    z-index: 1;
  }
`;
