import { css } from 'styled-components';

export const columnGap = (gap: string) => {
  return css`
    > * {
      &:not(:last-child) {
        margin-right: ${gap};
      }
    }
  `;
};

export const columnGapWrap = (gap: string) => {
  return css`
    > * {
      margin-right: ${gap};
    }
  `;
};

export const rowGap = (gap: string) => {
  return css`
    > * {
      &:not(:last-child) {
        margin-bottom: ${gap};
      }
    }
  `;
};

export const rowGapWrap = (gap: string) => {
  return css`
    > * {
      margin-bottom: ${gap};
    }
  `;
};

export const gap = (gap: string) => {
  return css`
    > * {
      &:not(:last-child) {
        margin-bottom: ${gap};
        margin-right: ${gap};
      }
    }
  `;
};
