import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { constVoid } from 'fp-ts/function';
import { PropsWithChildren } from 'react';

const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 200;

export type VideoElement = {
  playVideo: () => void;
};

export interface VideoProps extends Omit<React.VideoHTMLAttributes<HTMLVideoElement>, 'onPlaying'> {
  onPlaying?: (playing: boolean) => void;
  onWaiting?: () => void;
}

const Video = forwardRef<VideoElement, PropsWithChildren<VideoProps>>(
  ({ onPlaying = constVoid, onWaiting = constVoid, children, ...props }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [, setIsWaiting] = useState(false);

    const isWaitingTimeout = useRef<any>(null);
    const isPlayingTimeout = useRef<any>(null);

    useEffect(() => {
      if (!videoRef.current) {
        return;
      }

      const waitingHandler = () => {
        clearTimeout(isWaitingTimeout.current);

        isWaitingTimeout.current = setTimeout(() => {
          setIsWaiting(true);
          onWaiting();
        }, WAITING_DEBOUNCE_TIME);
      };

      const playHandler = () => {
        clearTimeout(isWaitingTimeout.current);
        clearTimeout(isPlayingTimeout.current);

        isPlayingTimeout.current = setTimeout(() => {
          setIsPlaying(true);
          setIsWaiting(false);
          onPlaying(true);
        }, PLAYING_DEBOUNCE_TIME);
      };

      const pauseHandler = () => {
        clearTimeout(isWaitingTimeout.current);
        clearTimeout(isPlayingTimeout.current);

        isPlayingTimeout.current = setTimeout(() => {
          setIsPlaying(false);
          setIsWaiting(false);
          onPlaying(false);
        }, PLAYING_DEBOUNCE_TIME);
      };

      const element = videoRef.current;
      element.addEventListener('waiting', waitingHandler);
      element.addEventListener('play', playHandler);
      element.addEventListener('playing', playHandler);
      element.addEventListener('pause', pauseHandler);

      return () => {
        clearTimeout(isWaitingTimeout.current);
        clearTimeout(isPlayingTimeout.current);

        element.removeEventListener('waiting', waitingHandler);
        element.removeEventListener('play', playHandler);
        element.removeEventListener('playing', playHandler);
        element.removeEventListener('pause', pauseHandler);
      };
    }, [videoRef, onPlaying, onWaiting]);

    const playVideo = useCallback(() => {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
      }
    }, [videoRef, isPlaying]);

    useImperativeHandle(ref, () => ({ playVideo }), [playVideo]);

    return (
      <video ref={videoRef} {...props}>
        {children}
      </video>
    );
  },
);

export default Video;
