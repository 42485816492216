import styled, { css } from 'styled-components';
import { columnGap } from './gap';

export const DivFlex = styled.div<{ $gap?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.$gap &&
    css`
      ${columnGap(props.$gap)};
    `}
`;
