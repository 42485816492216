import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
}

  html, body {
    font-family: 'Montserrat', sans-serif;
    background-color: #f8f7f5;
  }

  #root {
    display: flex;
    flex-direction: column;
    // cheat 100vh on mobile
    min-height: ${window.innerHeight}px;
  }

  a {
     text-decoration: none;
  }

  p {
    margin: 5px 0;
  }

  img {
    max-width: 100%;
  }
`;
