import React, { FC, useEffect, useState } from 'react';
import { useLayoutContext } from '@layout/Layout';
import { useResidentContext } from '../routes';
import { renderNullable } from '@shared/utils/render';
import BeforeCall from './before-call/BeforeCall';
import Calling from './calling/Calling';
import { CallStatus } from './model';
import InCall from './in-call/InCall';
import EndCall from './end-call/EndCall';
import * as Styled from './Call.styles';
import { usePageProps } from '@layout/page/hooks';
import callerImg from '@assets/mock/videos/caller.jpg';
import Page from '@layout/page/Page';
import Camera from '@shared/components/camera/Camera';

const Call: FC = () => {
  const [status, setStatus] = useState<CallStatus>('waiting');

  const [resident] = useResidentContext();

  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, hideHeader: true, hideFooter: true }));
  }, [resident, setProps]);

  const { pageProps, updatePageProps } = usePageProps({
    title: 'Appel',
    noPadding: true,
  });

  const cameraSuccess = () => {
    if (status === 'waiting') {
      if (resident.resident?.unavailable) {
        setStatus('no-response');
      } else {
        setStatus('calling');
      }
    }
  };

  const cameraFailure = () => {
    if (status === 'waiting') {
      if (resident.resident?.unavailable) {
        setStatus('no-response');
      } else {
        setStatus('calling');
      }
    }
  };

  return renderNullable(resident.resident, resident => (
    <Page {...pageProps}>
      <Styled.CallContainer>
        {status !== 'end-call' && (
          <Styled.CallVideoCamera $status={status}>
            <Camera
              onSuccess={cameraSuccess}
              onFailure={cameraFailure}
              fallback={<img src={callerImg} alt="appelant" />}
            />
          </Styled.CallVideoCamera>
        )}
        {status === 'waiting' && (
          <BeforeCall resident={resident} updatePageProps={updatePageProps} setStatus={setStatus} />
        )}
        {(status === 'calling' || status === 'no-response') && (
          <Calling status={status} resident={resident} updatePageProps={updatePageProps} setStatus={setStatus} />
        )}
        {(status === 'in-call' || status === 'call-ending') && (
          <InCall resident={resident} status={status} setStatus={setStatus} updatePageProps={updatePageProps} />
        )}
        {status === 'end-call' && <EndCall resident={resident} updatePageProps={updatePageProps} />}
      </Styled.CallContainer>
    </Page>
  ));
};

export default Call;
